<template>
  <v-main class="customMain">
    <PopupAlert v-model="showDelete" type="delete" @click:confirm="handleConfirm" />
    <PopupTop :is-show="showAlert" :errorMsg="errorMsg" />
    <BackOnTop title="手機載具設定" show-delete :is-carrier="storeCarrier" @click:delete="handleDelete" />
    <v-container fluid class="fp-container px-8 mb-4" style="margin-top: 14px;">
      <v-form ref="form" v-model="valid" lazy-validation>
        <FancyInput
          v-model="carrier"
          label="手機載具"
          :is-required="true"
          iconName="ic24Name.svg"
          placeholder="請輸入"
          :sizes="18"
          :rules="mobileCarrierRules"
          :isError="handleBlur(/^\/[0-9A-Z.+-]{7}$/.test(carrier), carrier)"
          hide-details="true"
          wrongHint="手機載具碼錯誤"
          :isChecking="isChecking"
          inputEl="invoiceCarrier"
          required
        />
      </v-form>
    </v-container>
    <BtnSign :canClick="!canNext" :style="{pointerEvents: !canNext ? 'auto':'none', color: !canNext ? 'white':'#6F6F6F', backgroundColor: !canNext ? '#FF9F4B':'#F2F2F2'}" text="完成" class="btn" @click="handleNext" />
  </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue'
import PopupTop from '@/components/Popup/PopupTop.vue'
import PopupAlert from '@/components/Popup/PopupAlert.vue'
import BtnSign from '@/components/BtnSign.vue'
import FancyInput from '@/components/FancyInput.vue'
import { updateCarrier, GetCarrier } from '@/apis/api.js'
export default {
  name: 'InvoiceIndex',
  components: { BackOnTop, BtnSign, FancyInput, PopupTop, PopupAlert },
  data: () => ({
    isChecking: false,
    valid: '',
    phone: '',
    carrier: '',
    errorMsg: '',
    showAlert: false,
    showDelete: false,
    storeCarrier: '',
    mobileCarrierRules: [
      v => !!v || '手機載具碼為必填項',
      v => /^\/[0-9A-Z.+-]{7}$/.test(v) || '手機載具碼錯誤',
    ],
  }),
  beforeCreate() {
    this.$store.state.isLoading = true
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.state.isLoading = false
    })
  },
  created() {
    if (
      !localStorage.getItem('phone') ||
      !localStorage.getItem('password') ||
      !localStorage.getItem('member_no')
    ) {
      this.$router.push('/login')
    } else {
      var parent = this
      GetCarrier(localStorage.getItem('phone'),localStorage.getItem('password'))
      .then(rsp => JSON.parse(rsp))
      .then(rsp => {
        parent.storeCarrier = rsp.carrier ? rsp.carrier : ''
        parent.carrier = rsp.carrier ? rsp.carrier : ''
      })
    }
  },
  methods: {
    handleBlur(error, item) {
      return error && !!item
    },
    handleConfirm() {
      var parent = this
      parent.carrier = ''
      this.showDelete = false
      updateCarrier(
        localStorage.getItem('phone'),
        localStorage.getItem('password'),
        parent.carrier
      ).then(rsp => {
        if(rsp){
          // parent.showAlert = true
          // parent.errorMsg = '刪除成功'
          // setTimeout(() => {
          //   parent.showAlert = false
          //   parent.$router.push('/member')
          // }, 3000)
          parent.$store.state.isShowAlert = 'invoice'
          parent.$router.push('/member');
        }else{
          parent.showAlert = true
          parent.errorMsg = '刪除失敗，請再試一次'
          setTimeout(() => {
            parent.showAlert = false
          }, 3000)
        }
      })
    },
    handleDelete() {
      this.showDelete = true
    },
    handleNext() {
      var parent = this
      if (/^\/[0-9A-Z.+-]{7}$/.test(parent.carrier)) {
        updateCarrier(
          localStorage.getItem('phone'),
          localStorage.getItem('password'),
          parent.carrier
        ).then(rsp => {
          if(rsp){
            // parent.showAlert = true
            // parent.errorMsg = '修改成功'
            // setTimeout(() => {
            //   parent.showAlert = false
            //   parent.$router.push('/member')
            // }, 3000)
            parent.$store.state.isShowAlert = 'invoiceset'
            parent.$router.push('/member');
          }else{
            parent.showAlert = true
            parent.errorMsg = '修改失敗，請再試一次'
            setTimeout(() => {
              parent.showAlert = false
            }, 3000)
          }
        })
      } else {
        parent.showAlert = true
        parent.errorMsg = '修改失敗，請再試一次'
        setTimeout(() => {
          parent.showAlert = false
        }, 3000)
      }
    },
  },
  computed: {
      canNext: function () {
          return this.storeCarrier === this.carrier;
      },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  margin-top: auto;
  margin-bottom: 40px;
}
.v-main {
  background-image: url('~@/assets/images/cat.png');
  background-size: 80%;
  background-position: bottom right;
  height: 100%;
}
.fp-container {
  margin: 12px 0;
  // padding: 10px 0 0 0;
  p.desc {
    font-size: 16px;
    line-height: 24px;
    color: $color_main_black;
    text-align: left;
  }
}
</style>
